/*
Theme: Flatfy Theme
Author: Andrea Galanti
Bootstrap Version 
Build: 1.0
http://www.andreagalanti.it
*/

$(window).load(function() { 
	//Preloader 
	$('#status').delay(300).fadeOut(); 
	$('#preloader').delay(300).fadeOut('slow');
	$('body').delay(550).css({'overflow':'visible'});
})

$(document).ready(function() {
	//animated scroll_arrow
	$(".img_scroll").hover(function () {
		$(this).toggleClass("animated infinite bounce");
	});
		
	//Wow Animation DISABLE FOR ANIMATION MOBILE/TABLET
	wow = new WOW(
	{
		mobile: false
	});
	wow.init();
		
	//MagnificPopup
	$('.image-link').magnificPopup({type:'image'});

	//SmothScroll
	$('a[href*=\\#]').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
		&& location.hostname == this.hostname) {
				var $target = $(this.hash);
				$target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
				if ($target.length) {
						var targetOffset = $target.offset().top;
						$('html,body').animate({scrollTop: targetOffset}, 600);
						return false;
				}
		}
	});
		
    //Subscribe
	if ($('.morph-button').length) {
            
	    new UIMorphingButton( document.querySelector( '.morph-button' ) );
	    // for demo purposes only
	    [].slice.call( document.querySelectorAll( 'form button' ) ).forEach( function( bttn ) { 
		    bttn.addEventListener( 'click', function( ev ) { ev.preventDefault(); } );
	    } );
    }

});

